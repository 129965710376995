import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/ui-elements/ui-elements/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { View } from 'react-native';
import { Text } from '@components/Text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "text"
    }}>{`Text`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Text} mdxType="Props" />
    <h3 {...{
      "id": "default-properties"
    }}>{`Default properties`}</h3>
    <p>
    <code>
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 12,
        lineHeight: 14,
        color: colors.text.primary,
    </code>
    </p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={2} __code={'<Text>Text</Text>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      Text,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Text mdxType="Text">Text</Text>
    </Playground>
    <h2 {...{
      "id": "h1"
    }}>{`H1`}</h2>
    <Playground __position={3} __code={'<View>\n  <Text type=\"h0\">Header 0</Text>\n  <Text type=\"h1\">Header 1</Text>\n  <Text type=\"h2\">Header 2</Text>\n  <Text type=\"h3\">Header 3</Text>\n  <Text type=\"h4\">Header 4</Text>\n  <Text type=\"h5\">Header 5</Text>\n  <Text type=\"h6\">Header 6</Text>\n  <Text type=\"h7\">Header 7</Text>\n  <Text type=\"h8\">Header 8</Text>\n</View>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      Text,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <View mdxType="View">
    <Text type="h0" mdxType="Text">Header 0</Text>
    <Text type="h1" mdxType="Text">Header 1</Text>
    <Text type="h2" mdxType="Text">Header 2</Text>
    <Text type="h3" mdxType="Text">Header 3</Text>
    <Text type="h4" mdxType="Text">Header 4</Text>
    <Text type="h5" mdxType="Text">Header 5</Text>
    <Text type="h6" mdxType="Text">Header 6</Text>
    <Text type="h7" mdxType="Text">Header 7</Text>
    <Text type="h8" mdxType="Text">Header 8</Text>
  </View>
    </Playground>
    <Playground __position={4} __code={'<View>\n  <Text type=\"th0\">Header 0</Text>\n  <Text type=\"th1\">Header 1</Text>\n  <Text type=\"th2\">Header 2</Text>\n  <Text type=\"th3\">Header 3</Text>\n  <Text type=\"th4\">Header 4</Text>\n  <Text type=\"th5\">Header 5</Text>\n  <Text type=\"th6\">Header 6</Text>\n  <Text type=\"th7\">Header 7</Text>\n  <Text type=\"th8\">Header 8</Text>\n  <Text type=\"th9\">Header 9</Text>\n  <Text type=\"th10\">Header 10</Text>\n  <Text type=\"th11\">Header 11</Text>\n  <Text type=\"th12\">Header 12</Text>\n  <Text type=\"th13\">Header 13</Text>\n  <Text type=\"th14\">Header 14</Text>\n</View>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      Text,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <View mdxType="View">
    <Text type="th0" mdxType="Text">Header 0</Text>
    <Text type="th1" mdxType="Text">Header 1</Text>
    <Text type="th2" mdxType="Text">Header 2</Text>
    <Text type="th3" mdxType="Text">Header 3</Text>
    <Text type="th4" mdxType="Text">Header 4</Text>
    <Text type="th5" mdxType="Text">Header 5</Text>
    <Text type="th6" mdxType="Text">Header 6</Text>
    <Text type="th7" mdxType="Text">Header 7</Text>
    <Text type="th8" mdxType="Text">Header 8</Text>
    <Text type="th9" mdxType="Text">Header 9</Text>
    <Text type="th10" mdxType="Text">Header 10</Text>
    <Text type="th11" mdxType="Text">Header 11</Text>
    <Text type="th12" mdxType="Text">Header 12</Text>
    <Text type="th13" mdxType="Text">Header 13</Text>
    <Text type="th14" mdxType="Text">Header 14</Text>
  </View>
    </Playground>
    <h2 {...{
      "id": "variation"
    }}>{`Variation`}</h2>
    <div>
  <Text type="title" mdxType="Text">Title</Text>
    </div>
    <div>
  <Text type="info" mdxType="Text">Info</Text>
    </div>
    <div>
  <Text type="warning" mdxType="Text">Warning</Text>
    </div>
    <div>
  <Text type="error" mdxType="Text">Error</Text>
    </div>
    <div>
  <Text type="small" mdxType="Text">Small</Text>
    </div>
    <div>
  <Text type="link" mdxType="Text">Small</Text>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      